/* Font Family */


/* Language */

*:lang(ar) {
    direction: rtl !important;
}


/* Global Styles */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
}

.print-container {
    width: 100%;
}

footer {
    display: none;
}

@media print {
    * {
        margin: 0;
        padding: 0;
        color: black;
        background-color: white;
        box-shadow: none;
    }

    body {
        font-size: 12pt;
        line-height: 1.5;
    }

    @page {
        /* Remove page numbers */
        size: auto;
        margin: 0;
    }

    @page :after {
        /* Hide page numbers */
        content: none;
    }

    .print-container {
        width: auto;
        margin: 0;
        padding: 0;
    }

    .ant-layout-sider,
    .print-button,
    .ant-layout-footer,
    header {
        display: none !important;
    }
}

[dir="ar"] .ant-pagination-item-link,
[dir="ar"] .ant-pagination-item-link {
    transform: rotate(180deg);
}

.ant-upload-list.ant-upload-list-picture-card,
.ant-upload-wrapper.css-dev-only-do-not-override-mxhywb .ant-upload.ant-upload-select {
    display: flex;
}

#edit-product-form span.ant-upload {
    width: 100%;
}

.ant-upload-wrapper.css-dev-only-do-not-override-mxhywb {
    display: flex;
    flex-direction: column;
}